import LanguageIdentifier from "components/languageIdentifier";
import Layout from "components/layout";
import React from "react";
import Seo from "components/seo";

const index = () => {
  return (
    <Layout>
      <Seo
        title="Free Language Detection | Detect Language to English and 150 more"
        description="Instantly detect and identify the language of any text. Use our language detection tool to find out what language you're dealing with quickly. Try it for free!"
        slug="/tools/language-detector"
      />
      <LanguageIdentifier />
    </Layout>
  );
};

export default index;
